<template>
  <DashboardAuthLayout>
    <template #header>
      <img src="@/assets/images/careercolor.svg" alt="Logo" />
    </template>

    <div class="my-9 p-4 md:p-9 bg-white rounded w-full">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col">
          <h1 class="font-cooper font-medium text-3xl text-[#090E11]">Recover Password</h1>
          <span class="font-GTWalsheimPro font-normal text-sm text-[#090E11]/80">Let’s get you sorted out.</span>
        </div>
        <div>
          <circle-progress :percent="33.3" size="64" :border-width="6" :border-bg-width="6" fill-color="#B34C99" empty-color="#B34C991F">
            <span class="flex flex-row items-center justify-between">
              <span class="font-bold text-3xl text-[#B34C99]">1</span>
              <span class="font-normal text-base text-black/40">/3</span>
            </span>
          </circle-progress>
        </div>
      </div>

      <div>
        <div class="pt-4 flex flex-col">
          <label for="email-address-icon" class="block mb-2 text-sm font-medium text-[#090E11]">Email Address</label>
          <div class="relative">
            <input
              type="email"
              id="email-address-icon"
              v-model="email"
              class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
            />
          </div>
        </div>

        <div class="pt-4">
          <button class="w-full bg-brand-original text-white p-4 rounded-lg" @click.prevent="submit">Next</button>
        </div>

        <div class="pt-4 text-center text-sm">
          <span>Remember your password?</span>
          <router-link class="ml-1 text-brand-primary" to="/login">Login</router-link>
        </div>
      </div>
    </div>
  </DashboardAuthLayout>
</template>

<script setup>
import DashboardAuthLayout from '@/layouts/DashboardAuthLayout.vue';
import CircleProgress from '@/components/CircleProgress.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { errorMessage, alert } from '@/utils/helper';

const store = useStore();
const router = useRouter();
const email = ref('');

const submit = async () => {
  try {
    const data = {
      email: email.value,
    };
    await store.dispatch('auth/forgetPasswordRequestOtp', data);
    await store.commit('global/setForm', {key: 'email', value: email.value});
    router.push('/reset-password-otp');
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
};
</script>

<style></style>
